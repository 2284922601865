<template>
    <div @mouseleave="timeoutToggleView" @mouseenter="resetTimeout">
        <div
            class="flex relative justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
            text-white shadow-md transition hover:bg-green-500 cursor-pointer"
            @click="toggleView"
        >
            <div class="flex">
                <fa-icon :icon="['fas', 'eye']" fixed-width class="cursor-pointer" />
            </div>
        </div>
        <div v-if="visible" class="floating-container absolute py-4 px-7 rounded-md shadow-md bg-green-400 text-white">
            <p class="text-center mb-2 pb-2 border-b border-white">
                {{ $t('calendar.calendar_settings') }}
            </p>
            <div class="flex w-full justify-between items-center mb-2">
                <p>
                    {{ $t('calendar.address_visible') }}
                </p>
                <div class="cursor-pointer" @click="toggleAddressVisibility">
                    <fa-icon v-if="addressVisible" :icon="['fas', 'eye']" fixed-width class="ml-3 cursor-pointer" />
                    <fa-icon v-else :icon="['fas', 'eye-slash']" fixed-width class="ml-3 cursor-pointer" />
                </div>
            </div>
            <div class="flex w-full justify-between items-center mb-2">
                <p>
                    {{ $t('calendar.city_visible') }}
                </p>
                <div class="cursor-pointer" @click="toggleCityVisibility">
                    <fa-icon v-if="cityVisible" :icon="['fas', 'eye']" fixed-width class="ml-3 cursor-pointer" />
                    <fa-icon v-else :icon="['fas', 'eye-slash']" fixed-width class="ml-3 cursor-pointer" />
                </div>
            </div>
            <div class="flex w-full justify-between items-center mb-2">
                <p>
                    {{ $t('calendar.client_name_visible') }}
                </p>
                <div class="cursor-pointer" @click="toggleClientVisibility">
                    <fa-icon v-if="clientVisible" :icon="['fas', 'eye']" fixed-width class="ml-3 cursor-pointer" />
                    <fa-icon v-else :icon="['fas', 'eye-slash']" fixed-width class="ml-3 cursor-pointer" />
                </div>
            </div>
            <div class="flex w-full justify-between items-center mb-2">
                <p>
                    {{ $t('calendar.employees_visible') }}
                </p>
                <div class="cursor-pointer" @click="toggleEmployeesVisibility">
                    <fa-icon v-if="employeesVisible" :icon="['fas', 'eye']" fixed-width class="ml-3 cursor-pointer" />
                    <fa-icon v-else :icon="['fas', 'eye-slash']" fixed-width class="ml-3 cursor-pointer" />
                </div>
            </div>
            <div class="flex w-full justify-between items-center mb-2">
                <p>
                    {{ $t('calendar.services_visible') }}
                </p>
                <div class="cursor-pointer" @click="toggleServicesVisibility">
                    <fa-icon v-if="servicesVisible" :icon="['fas', 'eye']" fixed-width class="ml-3 cursor-pointer" />
                    <fa-icon v-else :icon="['fas', 'eye-slash']" fixed-width class="ml-3 cursor-pointer" />
                </div>
            </div>
            <div class="flex w-full justify-between items-center mb-2">
                <p>
                    {{ $t('calendar.note_visible') }}
                </p>
                <div class="cursor-pointer" @click="toggleNoteVisibility">
                    <fa-icon v-if="noteVisible" :icon="['fas', 'eye']" fixed-width class="ml-3 cursor-pointer" />
                    <fa-icon v-else :icon="['fas', 'eye-slash']" fixed-width class="ml-3 cursor-pointer" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';

export default {
    props: {
        isWeekShowHideVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            visible:          false,
            timeout:          null,
            addressVisible:   Cookies.get('week_per_employee_plus_address_visible') !== 'false',
            cityVisible:      Cookies.get('week_per_employee_plus_city_visible') !== 'false',
            clientVisible:    Cookies.get('week_per_employee_plus_client_visible') !== 'false',
            employeesVisible: Cookies.get('week_per_employee_plus_employees_visible') !== 'false',
            servicesVisible:  Cookies.get('week_per_employee_plus_services_visible') !== 'false',
            noteVisible:      Cookies.get('week_per_employee_plus_note_visible') !== 'false',
        };
    },

    computed: {
        isAnotherVisible() {
            return this.isWeekShowHideVisible;
        },
    },

    watch: {
        isAnotherVisible() {
            if (this.isAnotherVisible) this.visible = false;
        },
    },

    methods: {
        toggleView() {
            this.visible = !this.visible;
            this.$emit('visibilityChange', this.visible);
        },

        toggleAddressVisibility() {
            this.addressVisible = !this.addressVisible;
            Cookies.set('week_per_employee_plus_address_visible', this.addressVisible, { expires: 365 });
            this.emitChange();
        },
        toggleCityVisibility() {
            this.cityVisible = !this.cityVisible;
            Cookies.set('week_per_employee_plus_city_visible', this.cityVisible, { expires: 365 });
            this.emitChange();
        },
        toggleClientVisibility() {
            this.clientVisible = !this.clientVisible;
            Cookies.set('week_per_employee_plus_client_visible', this.clientVisible, { expires: 365 });
            this.emitChange();
        },
        toggleEmployeesVisibility() {
            this.employeesVisible = !this.employeesVisible;
            Cookies.set('week_per_employee_plus_employees_visible', this.employeesVisible, { expires: 365 });
            this.emitChange();
        },
        toggleServicesVisibility() {
            this.servicesVisible = !this.servicesVisible;
            Cookies.set('week_per_employee_plus_services_visible', this.servicesVisible, { expires: 365 });
            this.emitChange();
        },
        toggleNoteVisibility() {
            this.noteVisible = !this.noteVisible;
            Cookies.set('week_per_employee_plus_note_visible', this.noteVisible, { expires: 365 });
            this.emitChange();
        },

        emitChange() {
            this.$emit('changeCalendarSettings', {
                addressVisible:   this.addressVisible,
                cityVisible:      this.cityVisible,
                clientVisible:    this.clientVisible,
                employeesVisible: this.employeesVisible,
                servicesVisible:  this.servicesVisible,
                noteVisible:      this.noteVisible,
            });
        },

        resetTimeout() {
            clearTimeout(this.timeout);
        },

        timeoutToggleView() {
            if (!this.visible) return;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.toggleView, 3000);
        },
    },
};
</script>

<style scoped>
    .floating-container {
        bottom: 55px;
        left: -169px;
    }
</style>
